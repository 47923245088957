// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[2]!../node_modules/resolve-url-loader/index.js??ruleSet[1].rules[1].oneOf[7].use[3]!../node_modules/react-scripts/node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[4]!../node_modules/@anchor/react-components/dist/lib/css/global.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  background-color: var(--background) !important;
  color: var(--text-primary) !important;
  transition: all 0.5s ease-in-out;
  overflow-x: hidden;
  overflow-y: hidden;
}

.white-text {
  color: white !important;
}

a {
  color: #058ec6;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

/* Safari Fixes */
input,
textarea,
select,
button {
  margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/App.scss"],"names":[],"mappings":"AAEA;EACE,8CAAA;EACA,qCAAA;EACA,gCAAA;EACA,kBAAA;EACA,kBAAA;AAAF;;AAEA;EACE,uBAAA;AACF;;AAEA;EACE,cAAA;EACA,qBAAA;AACF;;AAEA;EACE,0BAAA;AACF;;AAEA,iBAAA;AACA;;;;EAIE,SAAA;AACF","sourcesContent":["@import '~@anchor/react-components/dist/lib/css/global.css';\n// @import '../src/styles/var.scss';\nbody {\n  background-color: var(--background) !important;\n  color: var(--text-primary) !important;\n  transition: all 0.5s ease-in-out;\n  overflow-x: hidden;\n  overflow-y: hidden;\n}\n.white-text {\n  color: white !important;\n}\n\na {\n  color: #058ec6;\n  text-decoration: none;\n}\n\na:hover {\n  text-decoration: underline;\n}\n\n/* Safari Fixes */\ninput,\ntextarea,\nselect,\nbutton {\n  margin: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
