import * as React from 'react';
import './App.scss';
import '@maersk-global/fonts/maeu/fonts.css';
import '@maersk-global/mds-design-tokens/maersk/light/css/design-tokens-px.css';
import '@maersk-global/mds-foundations/css/foundations.css';
import { lazy } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ROUTES } from './constants/routes.constant';

const HeaderPage = lazy(() => import('../src/components/dashboard/header'));
const LandingPage = lazy(() => import('../src/pages/LandingPage/LandingPage'));
const Logs = lazy(() => import('../src/components/logs/logs'));
const Footer = lazy(() => import('../src/components/Footer/Footer'));
const Blueprints = lazy(() => import('./components/Blueprint/Blueprints'));
const BlueprintVersions = lazy(
  () => import('../src/components/Blueprint/BlueprintVersions')
);
const HealthStatus = lazy(
  () => import('../src/components/healthstatus/healthstatus')
);
const AnsibleRuns = lazy(
  () => import('../src/components/AnsibleRuns/ansibleruns')
);
const PlatformDetails = lazy(
  () => import('../src/components/PlatformDetails/PlatfromDetails')
);
const App = () => {
  return (
    <>
      <HeaderPage />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path={ROUTES.HealthStatus} element={<HealthStatus />} />
        <Route path={ROUTES.AnsibleRuns} element={<AnsibleRuns />} />
        <Route path={ROUTES.Logs} element={<Logs />} />
        <Route path={ROUTES.Blueprints} element={<Blueprints />} />
        <Route
          path={ROUTES.BlueprintVersions}
          element={<BlueprintVersions />}
        />
        <Route path={ROUTES.PlatformDetails} element={<PlatformDetails />} />
      </Routes>
    </>
  );
};

export default App;
